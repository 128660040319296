<template>
<div id="orderManage">
    <!-- 头部搜索 -->
  <div class="bg-white searchDom">
    <div class="flex align-center justify-end margin-bottom-24 header_search">
      <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
        <el-input @input="searKeyword" v-model="sendData.keyword" placeholder="请输入优惠券ID查询" suffix-icon="search"></el-input>
        <i style="cursor: pointer;" @click="searKeyword" class="el-icon-search"></i>
      </div>
      <el-button @click="addCoupons" style="height: 32px;width: 100px;padding: 0 10px;color: #FFFFFF;background-color: #4E9F5B;">添加优惠券</el-button>
      <el-button  style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;" @click="exportData">导出</el-button>
    </div>
  </div>
  <!--搜索列表-->
  <div class="searchList">
    <ul class="row margin-bottom-16 flex-start">
        <li v-for="(v,i) in dataList" :key="i">
		    <div class="fj_post" style="height:100%">
		        <div class="details">
                  <p class="flex justify-between">ID：<span>{{v.coupon_id}}</span></p>
                  <p class="flex justify-between">优惠券名称:<span>{{v.coupon_title}}</span></p>
			            <p class="flex justify-between">优惠券类型：<span>{{v.coupon_type|couponType}}</span></p>
                  <p class="flex justify-between">可领次数：<span>{{Number(v.coupon_count)-Number(v.coupon_get_total)}}</span></p>
                  <p class="flex justify-between">已领取：<span>{{v.coupon_get_total}}</span></p>
                  <p class="flex justify-between">未领取：<span>{{v.coupon_use_count}}</span></p>
                  <p class="flex justify-between">创建时间：<span>{{v.coupon_create_time*1000 | getDate}}</span></p>
                  <p class="flex justify-between">过期时间：
                      <span v-if="v.coupon_get_invalid_time=='4102416000'">无限期</span>
                      <span v-if="v.coupon_get_invalid_time!='4102416000'">{{ v.coupon_get_invalid_time*1000 | getDate }} </span>
                  </p>
                  <p class="flex justify-between">状态
                      <span v-if="v.coupon_state=='0'">禁用</span>
                      <span v-if="v.coupon_state=='1'">正常</span>
                  </p>
                  <p style="position: relative;right:0">
                   <span @click="editCoupon(v)" style="color: #4E9F5B;cursor:pointer;margin-left:20px">编辑</span>
                   <span @click="delCoupon(v.coupon_id)" style="color: #4E9F5B;cursor:pointer">删除</span>
                  </p>
			    </div>
			</div>
		</li> 
    </ul>
  </div>
  <!--分页-->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getTabList"
      :page.sync= "sendData.page"
      :limit.sync="sendData.limit"/>
  </div>

  <!-- 添加优惠券 -->
  <el-dialog id="couponModel" :close-on-click-modal="false" :visible.sync="showAddCoupModel">

    <div class="flex align-center justify-between">
      <span class="text-bold text-16">添加优惠券</span>
      <i @click="closeShowAddCoupModel" class="el-icon-close text-16 text-gray" style="cursor: pointer;"></i>
    </div>

    <el-form id="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px"
      class="demo-ruleForm margin-top-24">
      <el-form-item id="item_" label="优惠券名称:" prop="coupon_title">
        <el-input v-model="ruleForm.coupon_title" placeholder="4-12个字符"></el-input>
      </el-form-item>
      <div class="" style="width: 580px;">
        <el-form-item label="优惠券类型:" prop="coupon_type">
          <el-radio-group @change="chooseType" v-model="ruleForm.coupon_type">
            <el-radio label="1">新人满减券</el-radio>
            <el-radio label="2">新人折扣券</el-radio>
            <el-radio label="3">满减券</el-radio>
            <el-radio label="4">折扣券</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="ruleForm.coupon_type!='2'&&ruleForm.coupon_type!='4'?'规则:':'折扣券:'" required>
          <div class="flex">
            <span>满</span>
            <el-form-item prop="coupon_rule_1">
              <el-input type="number" style="width: 130px;margin: 0 10px;" v-model="ruleForm.coupon_rule_1"
                placeholder="请输入金额">
              </el-input>
            </el-form-item>
            <span>{{ruleForm.coupon_type!='2'&&ruleForm.coupon_type!='4'?'减':'享'}}</span>
            <el-form-item prop="coupon_rule_2">
            <el-input type="number" style="width: 130px;margin: 0 10px;" v-model="ruleForm.coupon_rule_2"
              :placeholder="ruleForm.coupon_type!='2'&&ruleForm.coupon_type!='4'?'满减':'折扣'"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="优惠券数量:" prop="coupon_count">
          <el-input type="number" v-model="ruleForm.coupon_count" placeholder="请输入优惠券数量"></el-input>
        </el-form-item>
        <el-form-item label="每人限领:" prop="coupon_limit">
          <el-input type="number" v-model="ruleForm.coupon_limit" placeholder="请输入每人限领次数"></el-input>
        </el-form-item>
        <el-form-item label="优惠券过期时间:">
          <el-radio-group v-model="lostTime">
            <el-radio label="1">长期有效</el-radio>
            <el-radio label="2">设置过期时间</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="lostTime==2" label="设置过期时间:" prop="coupon_get_invalid_time">
          <el-date-picker @change="changeTime" v-model="ruleForm.coupon_get_invalid_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用户领券有效期:" prop="coupon_use_invalid_second">
          <el-input v-model="ruleForm.coupon_use_invalid_second" type="number" placeholder="请输入卡券领取后多久过期(天) 例如: 30">
          </el-input>
        </el-form-item>
        <el-form-item id="item_2" label="适用品类:" prop="coupon_category">
          <el-select v-model="ruleForm.coupon_category" placeholder="请选择适用品类">
            <el-option label="全部" value="0"></el-option>
            <el-option label="文件和图片打印" value="1"></el-option>
            <el-option label="证件照打印" value="2"></el-option>
            <el-option label="照片冲洗" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态:" prop="coupon_state">
          <el-switch active-value="1" inactive-value="0" v-model="ruleForm.coupon_state"></el-switch>
        </el-form-item>
      </div>

    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="submitForm('ruleForm')">保 存</el-button>
    </span>
  </el-dialog>

  <!-- 编辑优惠券 -->
  <el-dialog id="couponModel" :visible.sync="showEditModel" :close-on-press-escape='false' :close-on-click-modal="false">

    <div class="flex align-center justify-between">
      <span class="text-bold text-16">编辑优惠券</span>
      <i @click="closeBox" class="el-icon-close text-16 text-gray" style="cursor: pointer;"></i>
    </div>

    <el-form id="form" :model="couponInfo" :rules="rules" ref="couponInfo" label-width="150px"
      class="demo-ruleForm margin-top-24">
      <el-form-item id="item_" label="优惠券名称:" prop="coupon_title">
        <el-input @change="changeInfo($event,'coupon_title')" v-model="couponInfo.coupon_title" placeholder="4-12个字符"></el-input>
      </el-form-item>
      <div class="" style="width: 580px;">
        <el-form-item label="优惠券类型:" prop="coupon_type">
          <el-radio-group disabled  v-model="couponInfo.coupon_type">
            <el-radio label="1">新人满减券</el-radio>
            <el-radio label="2">新人折扣券</el-radio>
            <el-radio label="3">满减券</el-radio>
            <el-radio label="4">折扣券</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="couponInfo.coupon_type!='2'&&couponInfo.coupon_type!='4'?'规则:':'折扣券:'" required>
          <div class="flex">
            <span>满</span>
            <el-form-item prop="coupon_rule_1">
              <el-input disabled type="number" style="width: 130px;margin: 0 10px;" v-model="couponInfo.coupon_rule_1"
                placeholder="请输入金额">
              </el-input>
            </el-form-item>
            <span>{{couponInfo.coupon_type!='2'&&couponInfo.coupon_type!='4'?'减':'享'}}</span>
            <el-form-item prop="coupon_rule_2">
            <el-input disabled type="number" style="width: 130px;margin: 0 10px;" v-model="couponInfo.coupon_rule_2"
              :placeholder="couponInfo.coupon_type!='2'&&couponInfo.coupon_type!='4'?'满减':'折扣'"></el-input>
            </el-form-item>
          </div>
        </el-form-item>
        <el-form-item label="优惠券数量:" prop="coupon_count">
          <el-input type="number" @change="changeInfo($event,'coupon_count')" v-model="couponInfo.coupon_count" placeholder="请输入优惠券数量"></el-input>
        </el-form-item>
        <el-form-item label="每人限领:" prop="coupon_limit">
          <el-input @change="changeInfo($event,'coupon_limit')" type="number" v-model="couponInfo.coupon_limit" placeholder="请输入每人限领次数"></el-input>
        </el-form-item>
        <el-form-item label="优惠券过期时间:">
          <el-radio-group @change="changeInvTiem"  v-model="lostTime">
            <el-radio label="1">长期有效</el-radio>
            <el-radio label="2">设置过期时间</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="lostTime=='2'" label="设置过期时间:" prop="coupon_get_invalid_time">
          <el-date-picker @change="changeTime" v-model="couponInfo.coupon_get_invalid_time" type="date" placeholder="选择日期" format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="用户领券有效期:" prop="coupon_use_invalid_second">
          <el-input @change="changeInfo($event,'coupon_use_invalid_second')" v-model="couponInfo.coupon_use_invalid_second" type="number" placeholder="请输入卡券领取后多久过期(天) 例如: 30">
          </el-input>
        </el-form-item>
        <el-form-item id="item_2" label="适用品类:" prop="coupon_category">
          <el-select @change="changeInfo($event,'coupon_category')" v-model="couponInfo.coupon_category" placeholder="请选择适用品类">
            <el-option label="全部" value="0"></el-option>
            <el-option label="文件和图片打印" value="1"></el-option>
            <el-option label="证件照打印" value="2"></el-option>
            <el-option label="照片冲洗" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="优惠券效果图:" prop="file_id">
          <el-input v-model="couponInfo.file_id" style="display: none;"></el-input>
          <div class="">
              <el-upload class="avatar-uploader" ref="frontImg" action="https://oss-campus-print.yihuichuang.com/"
                :show-file-list="false" :auto-upload="false" :data="upfileData" :with-credentials='false'
                :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :on-change="changeImg">
                <img v-if="couponInfo.coupon_logo" :src="couponInfo.coupon_logo" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
          </div>
        </el-form-item>
        <el-form-item label="状态:" @change="changeInfo($event,'coupon_state')" prop="coupon_state">
          <el-switch active-value="1" inactive-value="0" v-model="couponInfo.coupon_state"></el-switch>
        </el-form-item>
      </div>

    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="submitEditForm('couponInfo')">保 存</el-button>
    </span>
  </el-dialog>
</div>
</template>
<script>
import {coupon,couponAdd,couponEdit,couponDel} from '@/api/http'
import SparkMD5 from 'spark-md5'
import Pagination from '@/components/pagination'
export default {
    data(){
      return{
        dataList: [], //表格数据
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
       /* 查看附件弹窗*/
        sendData:{
          coupon_hide: '0',
          keyword: '',
          page: 1,
          limit: 10
        },
        type:'',
        sendObj:{},
        couponInfo:{},
        title:'',
        ruleForm: {
          coupon_hide: 0,
          coupon_state: '1'
        },
        rules: {
          coupon_title: [{
              required: true,
              message: '请输入优惠券名称',
              trigger: 'blur'
            },
            {
              min: 4,
              max: 12,
              message: '长度在 4 到 12 个字符',
              trigger: 'blur'
            }
          ],
          coupon_type:[
            { required: true, message: '请选择优惠券类型', trigger: 'change' }
          ],
          coupon_rule_1:[
            { required: true,message: '请输入规则1',trigger: 'blur' },
          ],
          coupon_rule_2:[
            { required: true,message: '请输入规则2',trigger: 'blur' },
          ],
          coupon_count:[
            { required: true, message: '请输入优惠券数量', trigger: 'blur' },
          ],
          coupon_limit:[
            { required: true, message: '请输入优惠券数量',trigger: 'blur' },
          ],
          coupon_get_invalid_time:[
            { required: true, message: '请选择过期时间', trigger: 'blur' }
          ],
          coupon_use_invalid_second:[
            { required: true, message: '请输入用户领券有效期', trigger: 'blur' },
          ],
          coupon_category:[
            { required: true, message: '请选择优惠券适用品类', trigger: 'change' }
          ],
          // file_id:[
          //   { required: true, message: '请选择上传优惠券效果图', trigger: 'blur' }
          // ],
        },
        type: '',

        lostTime: '1', //选择优惠券过期时间
        time1: "", //转换优惠券过期时间为时间戳，提交前赋值
        imageUrl: '', //上传图片
        //上传图片额为参数
        upfileData: {
          key: ''
        },
        fileMd5: '', //文件md5
        showEditModel:false,
        content:'',
        imgContent:'',
        editDataInfo:{},
        qaType:[],
        ruleForm:{},
        rules:{},
        input_title:'',
        showAddCoupModel:false,
        ruleForm1:{},
        content_nav:''//富文本内容
      }
    },
    filters: {
      couponType(val) {
        let arr = ['', '新人满减', '新人折扣', '满减', '折扣']
        let state = arr[Number(val)]
        return state
      }
    },
    created(){
      this.getTabList()
      if(!localStorage.getItem('yhc_coupon_state')){
        localStorage.setItem('yhc_coupon_state','999')
      }
      if(!localStorage.getItem('yhc_coupon_keyword')){
        localStorage.setItem('yhc_coupon_keyword','')
      }
    },
    methods:{ 
      changeInvTiem(e) {
        // let oldTime=this.couponInfo.coupon_get_invalid_time
        // this.couponInfo.coupon_get_invalid_time=''
        if (e == '1') {
          delete this.sendObj.coupon_get_invalid_time
        }
      },
      chooseType(e){
          console.log(e)
          this.type = e
      },
      changeInfo(e,type){
          this.sendObj[type] = e
      },  
      exportData(){
        console.log('sss')
        let obj = {
          coupon_hide:'1',
          keyword: localStorage.getItem('yhc_coupon_keyword'),
          coupon_state: localStorage.getItem('yhc_coupon_state'),
          limit: 9999
        }
        coupon(obj).then(res => {
          console.log('优惠券列表', res)
           if (res.data.code == '1' && res.data.csv_file) {
            window.open(res.data.csv_file)
          }
        })
      },
      /* 选择优惠券过期时间*/
      changeTime(e) {
        console.log(e)
        this.time1 = Date.parse(new Date(e+' 00:00:00')) / 1000
      },
      changeValue(e){
        // console.log(this.search)
        // localStorage.setItem('yhc_coupon_keyword',this.search)
        this.sendData.page = 1
        this.getTabList()
      },
      //添加优惠券
      addCoupons(){
          this.showAddCoupModel = true
      },
      closeShowAddCoupModel(){
          this.showAddCoupModel = false
      },
      closeBox(){
          this.showEditModel = false
          this.getTabList()
      },
      delCoupon(id){
        this.$confirm('是否确定删除该优惠券?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          couponDel({coupon_id: id}).then(res=>{
            console.log('删除当前优惠券',res.data)
            if(res.data.code == '1'){
              this.$message.success('删除成功')
              this.getTabList()
            }else{
              this.$message.error(res.info)
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      //编辑优惠券
      editCoupon(couponInfo){
        this.getTabList()
        if (couponInfo.coupon_get_invalid_time == '4102416000') {
          this.lostTime = '1'
          couponInfo.coupon_get_invalid_time = this.timestampToTime(couponInfo.coupon_get_invalid_time)
        } else {
          this.lostTime = '2'
          couponInfo.coupon_get_invalid_time = this.timestampToTime(couponInfo.coupon_get_invalid_time)
        }
        couponInfo.coupon_use_invalid_second = couponInfo.coupon_use_invalid_second / 86400
        this.couponInfo = couponInfo
        // this.couponInfo.coupon_get_invalid_time=''
        localStorage.setItem('yhc_coupon_info', JSON.stringify(couponInfo))
        console.log('编辑信息', couponInfo)
        this.showEditModel = true
      },
      timestampToTime(timestamp) {
        var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        const Y = date.getFullYear();
        const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
        const D = date.getDate();
        const h = date.getHours() + ':';
        const m = date.getMinutes() + ':';
        const s = date.getSeconds();
        const time = Y + '-' + M + '-' + D
        return time //时分秒可以根据自己的需求加上
      },
      /* 提交*/
      submitForm(formName) {
        console.log(this.ruleForm)
        let sendObj={}
        let that = this
        for(let item in this.ruleForm){
          sendObj[item]=that.ruleForm[item]
        }
        sendObj.coupon_get_invalid_time=this.time1
        sendObj.coupon_hide = 0
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.sendRequest(formName,sendObj)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      /* 发送请求*/
      sendRequest(formName,sendObj){
          couponAdd(sendObj).then(res=>{
          console.log('添加优惠券',res)
          if(res.data.code=='1'){
            this.$message.success('添加成功')
            this.$refs[formName].resetFields();
            this.showAddCoupModel = false
            this.getTabList()
          }else{
            this.$message.error(res.info)
          }
        })
      },
      submitEditForm(formName){
          let that = this
          
          if (!this.time1) {
            this.sendObj.coupon_get_invalid_time = Date.parse(new Date(this.couponInfo.coupon_get_invalid_time)) / 1000
          }
          if(this.lostTime=='1'){
            delete this.sendObj.coupon_get_invalid_time
          }
          this.sendObj.coupon_id = this.couponInfo.coupon_id
          this.sendObj.coupon_title = this.couponInfo.coupon_title
          this.sendObj.coupon_count = this.couponInfo.coupon_count
          this.sendObj.coupon_limit = this.couponInfo.coupon_limit
          this.sendObj.coupon_category = this.couponInfo.coupon_category
          this.sendObj.coupon_state = this.couponInfo.coupon_state
          this.sendObj.coupon_use_invalid_second = this.couponInfo.coupon_use_invalid_second
          console.log(this.sendObj)
          // return false
          this.$refs[formName].validate((valid) => {
            if (valid) {
              this.sendEditRequest(formName, this.sendObj)
            } else {
              console.log('error submit!!');
              return false;
            }
          });
       },
       //发送修改优惠券的请求
       sendEditRequest(formName,sendObj){
         couponEdit(sendObj).then(res=>{
           console.log('编辑优惠券',res.data)
           if(res.data.code == '1'){
             this.$message.success('编辑成功')
             this.$refs[formName].resetFields();
             this.showEditModel = false
             this.getTabList()
           }else{
             this.$message.error(res.info)
           }
         })
       },
      //文件上传成功之后的处理
        handleAvatarSuccess(res,file){
          this.save_file()
        },
        //上传文件之前的处理
        beforeAvatarUpload(file){
          console.log('上传前', file)
          let date = this.util.formatDate(new Date())
          let date_ = this.util.formatTime(new Date()).replace(/,/g, "")
          let randnum = (Math.random() * 9999999999).toFixed(0)
          let fileName = date_ + randnum
          this.upfileData.key = 'upload/xcx/' + date + '/' + fileName
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
          }
          return isLt2M;
        },
        //文件上传成功，保存文件
        save_file(file_uri){
          fileSave({file_uri:this.upfileData.key}).then(res=>{
            console.log('保存文件：',res.data)
            if(res.data.code == '1'){
              this.couponInfo.file_id = res.data.data.file_id
              this.sendObj.file_id = res.data.data.file_id
            }
          })
        },
        //选择重新上传轮播图文件
        changeImg(file){
          console.log(file)
          this.couponInfo.coupon_logo = URL.createObjectURL(file.raw)
          //上传文件
          this.upfileImg(file)
        },
      /* 计算文件md5*/
        upfileImg(file) {
          const _this = this
          var fileReader = new FileReader();
          //此处打印file可看到file下的raw为文件属性
          var dataFile = file.raw;
          let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
          var spark = new SparkMD5.ArrayBuffer();
          //获取文件二进制数据
          fileReader.readAsArrayBuffer(dataFile)
          //异步执行函数
          fileReader.onload = function(e) {
            spark.append(e.target.result);
            var md5 = spark.end()
            console.log('MD5', md5)
            /* 查找文件是否存在*/
            _this.find_file(md5)
          }
        },

        /* 检查文件是否存在*/
        find_file(sparkEnd) {
          fileFind({
            file_md5: sparkEnd
          }).then(res => {
            console.log('检查', res)
            if (res.data.code == '1') { //文件存在，无需上传
              this.couponInfo.file_id = res.data.data.file_id
              this.sendObj.file_id = res.data.data.file_id //文件id
            } else { //文件不存在，上传文件
              // this.upload('frontImg')
              console.log('可以重新上传文件')
              console.log(this.$refs.frontImg)
              this.$refs['frontImg'].submit()//上传文件
            }
          })
        },
      //获取意见反馈列表
      getTabList(){
        coupon(this.sendData).then(res=>{
          console.log('优惠券列表'+res.data)
          let {list,page,count,limit}=res.data.data
          if(res.data.code == '1' && list){
            this.rows = count
            this.dataList = list
          }else if(res.data.code=='1'&&res.data.data.csv_file){
            window.open(res.data.data.csv_file)
            this.sendData.limit=this.rows
          }
        })
      },
      //搜索
      searKeyword(){
        console.log(this.sendData.keyword)
        if(!this.sendData.keyword) return this.getTabList();
        // return this.dataList.filter(v=>{
        //   console.log(v)
        //   return v.coupon_title.includes(this.sendData.keyword);
        // })
        var _this = this
        var newItems = []
        this.dataList.map(function(item){
          // console.log(item)
          if(!item.coupon_id.search(_this.sendData.keyword)){
            console.log(item)
            newItems.push(item)
          }
        })
        // return newItems
        console.log(newItems)
        this.dataList = newItems
      },
      /* 清空输入框 */
      emptyKeyword(e){
        if(e.length=='0'){
          this.sendData.page=1
          this.getTabList()
        }
      },
      // 关闭弹窗
      closeEditModel() {
        this.showEditModel = false
        this.getTabList()
      },
      closeImgModel() {
        this.showImgModel = false
        this.getTabList()
      }
    },
    components:{
      Pagination
    }
}
</script>
<style>
    #orderManage{
  width: 100%;
}
#orderManage .header_search .el-input__inner {
  height: 20px !important;
  border: none !important;
  padding: 0 !important;
} 
#orderManage .searchDom{
    height: 70px;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 24px 80px 4px 24px;
    position: fixed;
    z-index: 20;
    left: 0;
    top: 60px;
    right: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
}
#orderManage .searchList{
  padding: 16px;
  margin-top: 76px;
}  
#orderManage ul{
  list-style: none;
  margin-left: -16px;
  margin-right: 16px;
}
#orderManage .searchList li{
  width: 19%;
  margin-right: 15px;
}
#orderManage ul li .order_number{
  border-bottom: 1px solid #ebedf0;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
#orderManage ul li .order h1 {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  color: #323233;
  display: block;
  margin-top: 2px;
}
#orderManage ul li .order font {
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  display: block;
  float: right;
}
.order #order_state{
  margin-top: -15px;
}
.order span{
    display: block;
    white-space: nowrap;
    color: #323233;
    font-size: 14px;
    font-weight: bold;
}
.order em{
  display: block;
  white-space: nowrap;
  color: #323233;
  font-size: 14px;
}
.order p {
    color: #323233;
    font-size: 14px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}
.order .order_kd{
  padding: 10px 14px;
  background: rgba(235,237,240,.3);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  margin-bottom: 20px;
}
.order .order_kd img{
  display: block;
  width: 80px;
  height: 36px;
  object-fit: cover;
  background: #ebedf0;
}
.order .order_kd i{
  display: block;
  width: 1px;
  height: 17px;
  background: #ebedf0;
  margin: 0 16px;
}
.order .order_kd .copy{
  display: block;
  white-space: nowrap;
  color: #79838b;
  font-size: 14px;
}
.pageNum {
    height: 60px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    position: fixed;
    z-index: 20;
    left: 144px;
    right: 28px;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 10%);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    color: #000;
    font-size: 14px;
}
.fj_post {
    background-color: #ffffff;
    border: 1px solid #f6f6f6;
    border-left: 4px solid transparent;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 30px;
    /**height: 350px;**/
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    -moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .09);
}
.fj_post .details{
  margin-top: 45px;
  margin-left: 15px;
}
.fj_post .thumb {
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    float: left;
    margin-right: 30px;
    text-align: center;
}

.fj_post .details {
    flex: 1;
}
.fj_post .details p {
    margin-bottom: 10px;
    color: #6d6d6d !important;
    font-size: 14px;
    position: relative;
}
.fj_post .details span{margin-right: 5px;}
.fj_post:hover {
    border: 1px solid #345e3b;
    border-left: 4px solid #345e3b;
}
#couponModel .el-dialog {
    border-radius: 8px;
    width: 780px;
  }

  #couponModel .el-dialog__header {
    display: none !important;
  }

  #couponModel .el-dialog__body {
    padding: 24px !important;
  }

  #couponModel .el-dialog__footer {
    text-align: center !important;
  }

  #couponModel .el-dialog__footer button {
    background-color: #4E9F5B !important;
    color: white;
    width: 260px;
    height: 40px;
  }

  #form #item_.el-form-item {
    border-bottom: 1px solid #F5F6F7 !important;
  }

  #form #item_.el-form-item .el-input__inner {
    border: none !important;
  }

  #form .el-form-item .el-form-item__content {
    text-align: left !important;
  }

  #form #item_2.el-form-item .el-input__inner {
    border: none !important;
    background-color: #F5F6F7 !important;
  }

  #form .el-form-item__label {
    text-align: left !important;
    font-size: 16px !important;
    color: #333333;
    font-weight: bold;
  }
</style>